<template>
  <div class="h-100 pt-5">
    <section class="main position-static d-flex flex-column justify-content-start align-items-center h-100 min-vh-100 w-100">
      <div class="py-5" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div class="content d-flex flex-column w-100 h-100" v-else-if="$store.state.currentCode || testMode">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1></h1>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="embed-responsive embed-responsive-16by9 bg-dark text-white">
                <player class="embed-responsive-item d-flex flex-column align-items-center justify-content-center" :mode="mode"></player>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-auto text-dark d-flex flex-column w-100 h-100">
          <div class="container d-flex flex-column w-100 h-100">
            <div class="row flex-grow-1">
              <div class="col-12 py-3">
                <p>
                  I år är ett annorlunda år men vi hoppas att vi ses snart igen. Med denna konsert önskar vi alla en God Jul och ett Gott Nytt År!<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content d-flex flex-column w-100 h-100" v-else>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="embed-responsive embed-responsive-16by9 bg-dark text-white">
                <redeem-form class="embed-responsive-item d-flex flex-column align-items-center justify-content-center"></redeem-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase"

  import RedeemForm from "@/components/redeem-form.vue"
  import Player from "@/components/player.vue"

  export default {
    name: "home",
    components: { RedeemForm, Player },
    data() {
      return {
        loading: true,
        settings: null,
      }
    },
    computed: {
      testMode() {
        return new URLSearchParams(window.location.search).get("wearejaws")
      },
      mode() {
        if(this.testMode) { return "test" }
        if(this.settings) { return this.settings.mode }
        return "offline"
      }
    },
    async mounted() {
      await this.$bind("settings", firebase.firestore().collection(`application`).doc(`settings`))
      this.loading = false
    }
  }
</script>

<style lang="scss" scoped>
  .spinner-border {
    width: 5rem;
    height: 5rem;
    border-width: 0.6em;
  }
</style>
