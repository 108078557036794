import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: null,
    currentCode: null
  },
  mutations: {
    setCurrentUser(state, parameters) {
      state.currentUser = parameters.currentUser
    },
    setCurrentCode(state, parameters) {
      state.currentCode = parameters.codeString
    }
  },
  plugins: [createPersistedState({ key: "jawsgeneric3", paths: ["currentUser", "currentCode"] })]
})
