<template>
  <div v-if="!loading" :key="mode">
    <jwplayer v-if="(codeValid && mode == 'live') || mode == 'test'"></jwplayer>

    <div class="h5 m-0" v-else-if="codeValid && mode == 'offline'">
      Player can't load.<br>
      Make sure to disable any ad-blocker installed.
    </div>

    <div class="bg-white align-self-end text-dark px-5 py-3" v-else-if="codeValid && mode == 'pre'">
      <vue-countdown :time="time" :transform="transformTime" v-if="time">
        <template slot-scope="props">
          <div class="d-flex">
            <h1 class="m-0" title="Days">{{ props.days }}</h1>
            <h1 class="m-0">:</h1>
            <h1 class="m-0" title="Hours">{{ props.hours }}</h1>
            <h1 class="m-0">:</h1>
            <h1 class="m-0" title="Minutes">{{ props.minutes }}</h1>
            <h1 class="m-0">:</h1>
            <h1 class="m-0" title="Seconds">{{ props.seconds }}</h1>
          </div>
        </template>
      </vue-countdown>
    </div>

    <div class="h1 m-0" v-else-if="codeValid && mode == 'post'">Tack för att ni tittade och God Jul!</div>

    <div v-else-if="codeValid && mode == 'ondemand'">
      <iframe src="https://player.buster.se/video/prmgd5zZp43pPMeYh" class="embed-responsive-item iframe-border" allowtransparency="true" frameborder="0" scrolling="no" seamless allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen></iframe>
    </div>

    <div v-else>
      <h1>Ogiltig kod</h1>
      <p>
        Försök att använda den igen.
      </p>

      <button type="button" class="btn btn-light mt-4" @click="closePlayer">Stäng</button>
    </div>
  </div>
  <div v-else>
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase"

  import VueCountdown from "@chenfengyuan/vue-countdown"
  import Jwplayer from "@/components/jwplayer"

  export default {
    name: "player",
    components: { VueCountdown, Jwplayer },
    props: {
      mode: {
        type: String,
        default: "offline"
      }
    },
    data() {
      return {
        loading: true,
        code: null,
        settings: null,
        eventStart: null,
        eventTimezone: "Europe/Stockholm",
        time: null,
      }
    },
    computed: {
      codeValid() {
        return this.code
        // return true
      },
      eventStartObject() {
        return this.$DateTime.fromISO(this.eventStart, { zone: this.eventTimezone })
      }
    },
    methods: {
      transformTime(props) {
        Object.entries(props).forEach(([key, value]) => {
          let digits = (value < 10) ? `0${value}` : value
          props[key] = `${digits}`
        })

        return props
      },
      closePlayer() {
        this.$store.commit("setCurrentCode", { codeString: null })
      }
    },
    async mounted() {
      await this.$bind("settings", firebase.firestore().collection(`application`).doc(`settings`))
      if(this.$store.state.currentCode) {
        await this.$bind("code", firebase.firestore().collection(`codes`).doc(this.$store.state.currentCode))
      }

      this.eventStart = this.$DateTime.fromISO(this.settings.eventStart.toDate().toISOString(), { zone: this.eventTimezone })

      let now = this.$DateTime.local().setZone(this.eventTimezone)
      let millis = this.eventStartObject.diff(now).toObject()

      this.time = millis.milliseconds

      this.loading = false
    }
  }
</script>

<style lang="scss" scoped>
  .spinner-border {
    width: 5rem;
    height: 5rem;
    border-width: 0.6em;
  }
</style>
