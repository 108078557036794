<template>
  <div id="player"></div>
</template>

<script>
  let playerScript = null

  if(window.jwplayer) {
    playerScript = require("jwplayer")
  }

  import { firebase } from "@/initializers/firebase"

  export default {
    name: "jwplayer",
    data() {
      return {
      }
    },
    mounted() {
      if(!playerScript) {
        if(this.$el) {
          this.$el.innerHTML = `<div class="text-center"><h1 class="m-0">Vi kunde inte ladda spelaren</h1><br><p>Kontrollera så ingenting blockerar <code>cdn.jwplayer.com</code>.</p></div>`
        }
        return
      }

      this.$nextTick(() => {
        playerScript.key = process.env.VUE_APP_JWPLAYER_KEY

        playerScript("player").setup({
          aspectratio: "fixed",
          autostart: true,
          cast: {},
          displaydescription: false,
          displaytitle: false,
          height: "100%",
          hlshtml: true,
          mute: false,
          preload: "none",
          primary: "html5",
          repeat: false,
          rtmp: { subscribe: "true" },
          sources: [{ file: process.env.VUE_APP_JWPLAYER_FILE, type: "hls" }],
          stretching: "uniform",
          width: "100%",
          ga: { }
        }).on("firstFrame", () => {
          firebase.firestore().collection(`logs`).add({ on: "firstFrame" })
        })
      })
    }
  }
</script>