<template>
  <main id="app" class="h-100" v-if="settings" :key="settings.cacheKey">
    <site-header></site-header>

    <router-view></router-view>
  </main>
</template>

<script>
  import { firebase } from "@/initializers/firebase"
  import SiteHeader from "@/components/site-header.vue"

  export default {
    name: "app",
    components: { SiteHeader },
    data() {
      return {
        settings: null,
      }
    },
    async mounted() {
      await this.$bind("settings", firebase.firestore().collection(`application`).doc(`settings`))
      this.loading = false
    }
  }
</script>